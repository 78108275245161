<template>
  <div class="footer">
    <div class="footer-main">
      <div class="footer-center clear_fix">
        <div class="fl footer-wrap">
          <dl class="fl wrap-list">
            <dt><a>新手指南</a></dt>
            <dd><router-link to="/register">注册新用户</router-link></dd>
            <dd><router-link to="/forgotPwd">找回密码</router-link></dd>
            <!-- <dd><router-link to="/helpcenter">帮助中心</router-link></dd> -->
            <dd><router-link to="/notice">公告栏</router-link></dd>
          </dl>
          <dl class="fl wrap-list">
            <dt><a>关于中再</a></dt>
            <dd><router-link to="/about">关于我们</router-link> </dd>
            <dd><router-link to="/about/contact">联系我们</router-link></dd>
          </dl>
          <!-- <dl class="fl wrap-list">
            <dt><a>资讯</a></dt>
            <dd><a href="http://jiage.zz91.com/" target="_blank">看行情</a></dd>
            <dd><a href="http://news.zz91.com/" target="_blank">资讯中心</a></dd>
          </dl> -->
          <dl class="fl wrap-list">
            <dt><a>快捷入口</a></dt>
            <dd><a href="https://supv.zhongzai360.com" target="_blank">监管端</a> </dd>
            <!-- <dd><router-link to="/about/contact">联系我们</router-link></dd> -->
          </dl>
        </div>
        <div class="foot-logo fr">
          <!-- <img src="../../assets/images/u101.png" alt=""> -->
        </div>
      </div>
      <div class="foot-ipc">
        CopyRight © 中再物贸云 2005 - 2022 电信增值许可：<a href="https://tsm.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=豫B2-20200390&type=xuke" target="_blank">豫B2-20200390</a> icp备案： <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">豫ICP备20006957号</a>
        <p class="fae">技术支持-浙江玖一再生供应链科技有限公司</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.footer {
  background-color: #001529;
  padding: 50px 0;
  min-width:990px;
  .footer-main {
    max-width: 1440px;
    // min-width: 990px;
    margin: 0 auto;
    @media screen and(max-width:1440px) {
      padding-left:15px;
      padding-right: 15px;
    }
    .footer-center{
      padding: 50px 0;
    }
    .footer-wrap {
      .wrap-list {
        margin-right: 100px;
        dt {
          margin-bottom: 12px;
          a {
            color: #eff3f5;
            &:hover{
              text-decoration: underline;
            }
          }
        }
        dd a {
          color: #9fa2a5;
          line-height: 25px;
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
    .foot-ipc{
      .icon-jinhun{
            background: url(../../assets/images/iconimg.png) repeat scroll -110px -126px;
            display: inline-block;
            height: 20px;
            margin:0 3px;
            vertical-align: middle;
            width: 18px;
      }
      a {
        color: #7a7d7f;
        outline: none;
        cursor: pointer;
        transition: color .3s;
        text-decoration: none;
        &:hover {
          color: @primary;
        }
      }
      text-align: center;
      color: #7a7d7f;
    }
    .fae {
      margin-top: 6px;
    }
    .foot-logo {
      img {
        width: 114px;
        height: 114px;
      }
      div{
        padding-top: 10px;
        p{
        color: #9fa2a5;
        line-height: 20px;
      } 
      }
     
    }
  }
}
</style>