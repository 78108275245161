import cookies from "js-cookie";

const record = {
  get: (key) => {
    const value = cookies.get(key);
    if (value && value.indexOf("{") > -1 && value.indexOf("}") > -1) {
      try {
        return JSON.parse(value);
      } catch (err) {
        return value;
      }
    } else {
      return value;
    }
  },
  set: (key, value) => cookies.set(key, value),
  remove: (key) => cookies.remove(key),
};
export default record;
