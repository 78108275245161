import request from '@/utils/request'

// 登录
export function login(data) {
    return request({
        url: '/trade/login',
        method: 'post',
        data,
    })
}
export function phonneLogin(params) {
    return request({
        url: '/trade/phonelogin',
        method: 'post',
        params,
    })
}

// 注册
export function save(data, smsCode) {
    return request({
        url: '/trade/save?smsCode=' + smsCode,
        method: 'post',
        data,
    })
}

// 获取验证码
export function sendSms(phone) {
    return request({
        url: '/trade/sendsms',
        method: 'get',
        params: {
            phone
        },
    })
}

// 退出登录
export function exit(username) {
    return request({
        url: '/trade/exit',
        method: 'get',
        params: {
            username
        },
    })
}

// 自然人列表
export function naturalList(params) {
    return request({
        url: '/account/individual/list',
        method: 'get',
        params
    })
}

// 店铺列表
export function storeList(params) {
    return request({
        url: '/account/supplier/list',
        method: 'get',
        params
    })
}


// 自然人详情
export function getindividual(individualId) {
    return request({
        url: '/account/getindividual',
        method: 'get',
        params: {individualId}
    })
}


// 查询申请表详情
export function queryApplyDetail(regionId,individualId) {
    return request({
        url: '/trade/supplier/queryApplyDetail',
        method: 'get',
        params: {regionId,individualId}
    })
}

// 查询账号基本信息
export function info(regionId) {
    return request({
        url: '/account/party/info',
        method: 'get',
        params: {regionId}
    })
}

// 更新用户基本信息
export function update(data) {
    return request({
        url: '/account/party/update',
        method: 'post',
        data
    })
}

// 编辑人保存
export function editApplySave(data) {
    return request({
        url: '/trade/supplier/editApplySave',
        method: 'post',
        data
    })
}

// 查询自然人在某税区是否已申请入驻
export function check(individualId, regionId) {
    return request({
        url: '/trade/supplier/individual/region/check',
        method: 'post',
        data: {individualId, regionId}
    })
}

// 修改密码发送短信验证码
export function sms(data) {
    return request({
        url: '/account/edit/new/phone/sms',
        method: 'post',
        data
    })
}

// 忘记密码
export function upPwd(data) {
    return request({
        url: '/trade/update/pwd',
        method: 'post',
        data
    })
}

// 修改密码发送短信验证码
export function accountSms(data) {
    return request({
        url: '/account/edit/password/sms',
        method: 'post',
        data
    })
}

// 保存新密码
export function saveNewPwd(data) {
    return request({
        url: '/account/edit/password/save',
        method: 'post',
        data
    })
}

// 修改手机发送新手机的短信验证码
export function editNewPhone(phone) {
    return request({
        url: '/account/edit/new/phone/sms',
        method: 'post',
        data: {phone}
    })
}

// 保存新手机号
export function saveNewPhone(data) {
    return request({
        url: '/account/edit/phone/save',
        method: 'post',
        data
    })
}

// 重新发送签署短信
export function send(params) {
    return request({
        url: '/account/send',
        method: 'get',
        params
        })
    }
// 获取本机手机号
export function editPhoneSms(data) {
    return request({
        url: '/account/edit/phone/sms',
        method: 'post',
        data
    })
}
// 用户名手机号效验
export function effect(data) {
    return request({
        url: '/trade/effect',
        method: 'post',
        data
    })
}
// 忘记密码手机号校验
export function effectPhone(data) {
    return request({
        url: '/trade/effect/phone',
        method: 'post',
        data
    })
}
// 撤销申请
export function delApply(data) {
    return request({
        url: '/trade/supplier/apply/delete',
        method: 'post',
        data
    })
}

// 添加申请表回显数据
export function echo(params) {
    return request({
        url: '/trade/supplier/apply/echo',
        method: 'get',
        params
    })
}