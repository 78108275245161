<template>
  <div class="units-content">
    <MdTitle label="合作单位" iconName="dribbble-square"></MdTitle>
    <div class="units-imgs clear_fix">
      <div class="units-imgs-item">
        <a href="https://bank.pingan.com/" target="_blank">
          <img src="../../assets/images/unit1.png" class="units-imgs-pa"/>
        </a>
      </div>
      <div class="units-imgs-item">
        <a href="http://www.zz91.com/" target="_blank">
          <img src="../../assets/images/logo.jpg" class="units-imgs-91"/>
        </a>
      </div>

      <div class="units-imgs-item">
        <a href="http://www.foundry.org.cn/" target="_blank">
          <img src="../../assets/images/中国铸造协会.png"/>
        </a>
      </div>
      <div class="units-imgs-item">
        <a href="http://www.ciar.org.cn/" target="_blank">
          <img src="../../assets/images/CIAR中国再生资源产业技术创新战略联盟.png"/>
        </a>
      </div>
      <div class="units-imgs-item item-center">
        <a href="http://www.cmra.cn/index.html" target="_blank">
          <img src="../../assets/images/中国有色金属工业协会再生金属分会.png"/>
        </a>
      </div>
    </div>
    <div class="units-imgs clear_fix">
     <div class="units-imgs-item">
        <a href="http://www.czbank.com/cn/index.shtml" target="_blank">
          <img src="../../assets/images/unit2.png" class="units-imgs-zs"/>
        </a>
      </div>
      <div class="units-imgs-item">
        <a href="http://www.camu.org.cn/" target="_blank">
          <img src="../../assets/images/u511.png"/>
        </a>
      </div>
      <div class="units-imgs-item">
        <a href="http://www.crra.com.cn/" target="_blank">
          <img src="../../assets/images/u514.png"/>
        </a>
      </div>
      <div class="units-imgs-item item-center">
        <a href="#">
          <img src="../../assets/images/河南有色金属行业协会再生金属分会.png" class="centet-img2"/>
        </a>
      </div>
      <div class="units-imgs-item item-center">
        <a href="#">
          <img src="../../assets/images/资源再生杂志.png"/>
        </a>
      </div>
    </div>
    <!-- <ul class="units-labels clear_fix">
      <li>中塑在线</li> 
      <li>招商宝</li> 
      <li>家居建材网</li> 
      <li>新能源产业</li> 
      <li>商机网</li> 
      <li>云同网</li> 
    </ul> -->
  </div>
</template>
<script>
import MdTitle from './MdTitle.vue'
export default {
  components:{
    MdTitle
  }
}
</script>
<style lang="less" scoped>
  .units-content{
    max-width: 1440px;
    min-width: 990px;
    margin: 0 auto;
    background-color: #fff;
    padding: 35px;
    .units-imgs{
      margin-top: 20px;
      .units-imgs-item{
        float: left;
        width: 20%;
        .units-imgs-91 {
          width: 140px;
          height: 33px;
          margin-top: 10px;
          margin-left: 16px;
        }
        .units-imgs-pa {
          width: 140px;
        }
        .units-imgs-zs {
          height: 48px;
        }
        img{
          max-width: 100%;
          // width: 140px;
          height: 54px;
          position: relative;
          // left: -15px;
        }
      }
      .item-center {
        text-align: center;
        .center-img {
          margin-right: 20px;
        }
        .centet-img2 {
          margin-right: 12px;
        }
      }
    }
    
    .units-labels{
      li{
        float: left;
        width: 20%;
        // margin-right:80px;
        margin-top: 15px;
        font-size: 16px;
        &:nth-child(3n+0){
          margin-right: 0;
        }
      }
      
    }
  }
</style>