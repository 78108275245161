<template>
  <div class="header">
    <div class="header-main clear_fix">
      <div class="header-left fl">
        <span v-if="hasLogin">
          欢迎回来，<span class="header-name">{{ username() }}</span> ！
          <!-- <router-link class="user-link c666" to="/account-center/settings">账号管理</router-link>  -->
          <a-space :size="10">
          <router-link
            class="call-center-show gap"
            to="/account-center/settings"
            >账号管理</router-link>
          <span class="user-link" @click="logout">退出登录</span>
          </a-space>
        </span>
        <span v-else>
          您好，欢迎来到中再物贸云！
          <router-link to="/login" class="h-left-link">请登录</router-link> |
          <router-link class="h-left-link" to="/register">免费注册</router-link>
        </span>
      </div>
      <div class="header-right fr clear_fix">
        <template v-if="username()">
          <router-link class="fl call-center-show gap" to="/switch-buyer"
            >买家中心</router-link
          >
          <router-link class="fl call-center-show gap" to="/switch-seller"
            >卖家中心</router-link
          >
        </template>
        <router-link class="fl call-center-show gap" to="/application"
          >申请入驻</router-link
        >
        <div class="fr call-center">
          <div class="call-center-show">
            产品介绍 <a-icon type="down" class="down-icon" />
          </div>
          <ul class="c-dorpdown">
            <li><router-link to="/about/contact">联系我们</router-link></li>
            <!-- <li><router-link to="/helpcenter">帮助中心</router-link></li> -->
            <li><router-link to="/about">关于我们</router-link></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { exit } from '@/api/user.js'
import cookie from '@/utils/js-cookie.js'
export default {
  data() {
    return {
      visible: false
    }
  },
  computed: {
    ...mapState(['hasLogin', 'userMenu', 'checkId']),
    ...mapGetters(['getRoleP', 'getRoleS'])
  },
  methods: {
    handleMenuClick(e) {
      if (e.key === '3') {
        this.visible = false
      }
    },
    username() {
      return (
        cookie.get('token') && cookie.get('username')
      )
    },
    logout() {
      exit(this.username()).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.$store.commit('logout')
          this.$router.push('/login')
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  // max-width: 1440px;
  // min-width: 990px;
  background-color: #f5f4f4;
  border-bottom: 1px solid #e6e6e6;
  .header-main {
    height: 40px;
    max-width: 1440px;
    min-width: 990px;
    margin: 0 auto;
    @media screen and(max-width:1440px) {
      padding-left:15px;
      padding-right: 15px;
    }
    .header-left {
      font-size: 14px;
      line-height: 40px;
      .header-name {
        color: @primary
      }
      .h-left-link {
        color: @primary;
        margin: 0 5px;
      }
      .h-left-link:hover {
        text-decoration: underline;
      }
      .user-link {
        color: #aaaaaa;
        margin: 0 5px;
        cursor: pointer;
      }
      .c666 {
        color: #666;
      }
    }
    .header-right {
      .gap {
        margin-left: 15px;
      }
      .call-center-show {
        color: #666;
        line-height: 40px;
        display: block;
        height: 40px;
      }
      .call-center:hover {
        background: #fff;
        border: 1px solid #e6e6e6;
        border-top: 0;
        border-bottom: 0;
        .down-icon {
          transform: rotate(-180deg);
          transition: transform 0.2s;
        }
        .c-dorpdown {
          display: block;
        }
      }
      .call-center {
        margin-left: 15px;
        width: 80px;
        position: relative;
        // padding: 0 10px;
        text-align: center;
        .c-dorpdown {
          // list-style: none;
          border: 1px solid #e6e6e6;
          border-top: 0;
          background: #fff;
          display: none;
          line-height: 30px;
          position: absolute;
          left: -1px;
          top: 40px;
          z-index: 1;
          width: 80px;
        }
      }
      a {
        color: #666;
        &:hover {
          text-decoration: underline;
          color: @primary;
        }
      }
    }
  }
}
a {
  color: #666;
  &:hover {
    text-decoration: underline;
    color: @primary;
  }
}
</style>