<template>
      <button>
          <slot />
      </button>
</template>

<script>
export default {
  props: {

  }
}
</script>

<style lang="less" scoped>
button {
    cursor: pointer;
    width: 300px;
    height: 45px;
    background-color: @subColor;
    font-size: 16px;
    border: none;
    color: #FFFFFF;
    &:hover {
        opacity: .8;
    }
}
</style>
