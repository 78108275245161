<template>
    <div class="border">
        <div class="nav">
            <router-link to="/">
                <img src="@/assets/images/logo2.png" alt="">
            </router-link>

            <router-link class="go-home" v-if="$route.name === 'Login' || $route.name === 'Result'" to="/">进入网站首页</router-link>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
.border {
    border-bottom: 1px solid @border;
    background: #fff;
}
.nav {
    max-width: 1440px;
    min-width: 990px;
    @media screen and (max-width: 1440px){
        padding: 0 15px;
    }
    height: 100px;
    background-color: #fff;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
        width: 177px;
    }
    .go-home {
        font-size: 14px;
    }
}
</style>
