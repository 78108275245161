import 'core-js/stable';
import 'regenerator-runtime/runtime';
// import "babel-polyfill"
import Vue from 'vue'
import Antd from 'ant-design-vue/es'
import 'ant-design-vue/dist/antd.less'
import '@/utils/index.less'
import App from './App.vue'
import {dispatchEventStroage} from './utils/tools'
import store from './store'
import router from './router'
import axios from './utils/request'
import cookie from '@/utils/js-cookie.js'
// formdata 兼容ie的 polyfill
import '@/utils/formdata.js'
import '@/utils/MouseEvent.js'
import './assets/css/reset.css'
import './assets/css/iconfont.css'
import './assets/css/iconfontTwo.css'
import './assets/css/icon/iconfont.css'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
// import VueImageHandler from 'vue-image-handler'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap,{ak:'GMP2NGiWvhQiw59n1fBPoTtTlNgBxe1K'})
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.prototype.$axios = axios
// import { Button } from 'ant-design-vue';
Vue.use(Antd)
// Vue.use(VueImageHandler)
Vue.use(dispatchEventStroage)

// Vue.component(Button.name, Button)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  // 需要授权的路由
  if (to.meta.auth) {
    const islogin = cookie.get('hasLogin')
    if (islogin) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});
async function go() {
  try {
    if (cookie.get('token') && cookie.get('username')) {
      await store.dispatch('getuserMenu')
    }
  } catch(err) {console.log(err);}
  
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}
go()
