<template>
  <div class="input" :class="[ruleResult && 'input-error', isFocus && 'input-focus']">
    <div
      v-if="hasClear"
      class="clear"
      :class="(move || isFocus) && 'clear-focus'"
      @mouseover="move = true"
      @mouseleave="move = false">
      <img @click="$emit('clear', '')" src="../assets/images/u895.svg" alt="">
    </div>
    <input
      :type="type"
      :value="value"
      @focus="isFocus=true,ruleResult=false;$emit('focus', $event)"
      :maxlength="max"
      @blur="isFocus=false;$emit('blur')"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="$emit('keyupEnter')"
      :autocomplete="isPwd ? '' : 'new-password'"
      >
    <transition name="fade-transform" mode="out-in">
      <div class="error-msg" v-if="rule.errorMsg && ruleResult">
        <span>{{ rule.errorMsg }}</span>
      </div>
    </transition>

    <div class="input-icon">
      <slot name="icon"></slot>
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'InputU',
  props: {
    // input类型
    type: {
      type: String,
      default: 'text'
    },
    value: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: ''
    },
    // 最大长度
    max: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: ''
    },
    // 占位符
    placeholder: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: ''
    },
    // 是否有清除按钮
    hasClear: {
      type: Boolean,
      default: true
    },
    // 是否记住密码
    isPwd: {
      type: Boolean,
      default: true
    },
    // 规则
    rule: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {

  },
  data () {
    return {
      isFocus: false,
      ruleResult: false,
      move: false
    }
  },
  methods: {
    // 判断规则是否正确
    verifyRule () {
      if (this.rule.rule) {
        this.ruleResult = !this.rule.rule.test(this.value)
        return !this.ruleResult
      }
    }
  }
}
</script>

<style lang="less" scoped>
.input-icon {
    position: absolute;
    left: 0;
    top: 0;
    line-height: 50px;
    width: 50px;
    margin: 0 auto;
    text-align: center;
    font-size: 20px;
    color: #b3b3b3;
}
.input {
    height: 50px;
    width: 300px;
    line-height: 50px;
    padding-left: 50px;
    padding-right: 40px;
    position: relative;
    border: 1px solid rgba(228, 228, 228, 1);
    line-height: initial;
    input {
        font-size: 14px;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        background-color: transparent;
        color:#666666;
    }
    .clear {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity .5s;
        line-height: normal;
        img {
            width: 20px;
            height: 20px;
        }
    }
    .clear-focus {
        opacity: 1;
    }
}
.error-msg {
    width: max-content;
    color: @error;
    line-height: 50px;
    position: absolute;
    transition: opacity .3s;
    left: 120%;
    top: 0;
}
.input-error {
    border: 1px solid @error;
}
.input-focus {
    border: 1px solid @primary;
}
input::-webkit-input-placeholder {
    color: rgb(204, 204, 204);
}
input::-moz-input-placeholder {
    color: rgb(204, 204, 204);
}
input::-ms-input-placeholder {
    color: rgb(204, 204, 204);
}
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: opacity 0.2s;
}

.fade-transform-enter {
  opacity: 0;
}

.fade-transform-leave-to {
  opacity: 0;
}
</style>
