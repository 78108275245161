<template>
  <div>
    <div class="content-box">
      <div class="content-main">
        <ContentLeft class="fl" :data="categoryList"></ContentLeft>
        <ContentRight class="fr"></ContentRight>
      </div>
      <!-- 滚动图片 -->
      <BandSwiper></BandSwiper>
    </div>
    
    <!-- 货源地 -->
    <Headstream style="margin-top:30px"></Headstream>
    <!-- 合作单位 -->
    <Units style="margin:30px auto"></Units>
  </div>
</template>
<script>
import BandSwiper from './BandSwiper'
import Headstream from './Headstream'
import Units from './Units'
import ContentRight from './ContentRight'
import ContentLeft from './ContentLeft'
import { categoryTree } from '../../api/store'
export default {
  data() {
    return {
      categoryList:[]
    };
  },
  components:{
    BandSwiper,
    Headstream,
    Units,
    ContentRight,
    ContentLeft
  },
  computed: {},
  methods:{
    // 获取左边行业分类
    getCategoryTree () {
      categoryTree().then(res =>{
        if(res.code === 200) {
          this.categoryList = res.data
        } else {
          this.$message.error(res.message);
        }
      }) 
    }
  },
  created() {
    this.getCategoryTree()
  },
};
</script>
<style lang="less">
.content-box{
  position: relative;
  min-width: 990px;
  
  .content-main{
    position: absolute;
    left: 50%;
    width: 100%;
    // margin-left: -720px;
    transform: translateX(-50%);
    top: 0;
    max-width: 1440px;
    min-width: 990px;
    z-index: 2;
    height: 1px;
    
  }
}
</style>