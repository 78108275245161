import Vue from 'vue'
import VueRouter from 'vue-router'
import BadicLayout from '../components/BadicLayout/BadicLayout'
// import BreadcrumbLayout from '../layout/BreadcrumbLayout'
import Index from '../views/index/Index'
// import Home from '../views/Home.vue'
import notFound from '../views/404.vue'
import Login from '@/views/user/Login'
// import { component } from 'vue/types/umd'
// import RouterView from '../views/details/RouterView.vue'
// import RouterViewList from '../views/list/RouterView.vue'
import Biocenose from '../views/biocenose.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'BadicLayout',
    component: BadicLayout,
    redirect: '/index',
    meta: { title: '首页', auth: false },
    children: [{
      path: '/index',
      name: 'index',
      component: Index,
      meta: { title: '首页', auth: false },
    },
    {
      path: '/product-list',
      name: 'ProductList',
      component: () => import(/* webpackChunkName: "about" */ '@/views/list/ProductList.vue'),
      meta: { title: '搜索结果页' },
    },
    {
      path: '/store-list',
      name: 'StoreList',
      component: () => import(/* webpackChunkName: "about" */ '@/views/list/StoreList.vue'),
      meta: { title: '搜索结果页' },
    },
    {
      path: '/product-details',
      name: 'ProductDetails',
      component: () => import(/* webpackChunkName: "about" */ '@/views/details/ProductDetails.vue'),
      meta: { title: '产品详情' , auth: true},
    },
    {
      path: '/shop/s:id',
      name: 'Shop',
      component: () => import(/* webpackChunkName: "about" */ '@/views/details/StoreDetails.vue'),
      meta: { title: '店铺详情' },
    },
    {
      path: '/commodity',
      name: 'Commodity',
      component: () => import('../views/content/Commodity.vue'),
      meta: { title: '商品详情', auth: true },
    },
    {
      path: '/edit-product',
      name: 'EditProduct',
      component: () => import('../views/content/EditProduct.vue'),
      meta: { title: '商品编辑', auth: true },
    },
    {
      path: '/blank',
      name: 'Blank',
      component: () => import('../views/details/Blank.vue'),
      meta: { auth: true },
    },
    // 交易中心
    {
      path: '/seller-content',
      component: () => import('../views/content/SellerContent.vue'),
      meta: { title: '交易中心', auth: true },
      children: [
        {
          path: '/seller-content/',
          name: 'SellerContent',
          component: () => import('../views/content'),
          meta: { title: '卖家中心', auth: true },
        },
        {
          path: '/seller-content/store-manage',
          name: 'StoreManage',
          component: () => import('../views/content/StoreManage.vue'),
          meta: { title: '商品管理', auth: true },
        },
        {
          path: '/seller-content/order-manage',
          name: 'OrderManage',
          component: () => import('../views/content/OrderManage.vue'),
          meta: { title: '订单管理', auth: true },
        },
      ]
    },
    {
      path: '/add-product',
      name: 'AddProduct',
      component: () => import(/* webpackChunkName: "about" */ '@/views/content/AddProduct.vue'),
      meta: { title: '商品添加', auth: true },
    },
    {
      path: '/order-details',
      name: 'OrderDetails',
      component: () => import(/* webpackChunkName: "about" */ '@/views/details/OrderDetails.vue'),
      meta: { title: '订单详情', auth: true },
    },
    {
      path: '/Frequently',
      name: 'Frequently',
      component: () => import('../views/helpcenter/Frequently'),
      meta: { title: '常见问题' }
    },
    // 申请入驻
    {
      path: '/application',
      name: 'Application',
      component: () => import('../views/application/Application'),
      meta: { title: '申请入驻', auth: false }
    },
    {
      path: '/buyer',
      name: 'Buyer',
      component: () => import('../views/application/Buyer.vue'),
      meta: { title: '买家入驻', auth: true },
    },
    {
      path: '/bankInformation',
      name: 'BankInformation',
      component: () => import('../views/application/BankInformation.vue'),
      meta: { title: '银行信息', auth: true },
    },
    {
      path: '/perfectInfo',
      name: 'PerfectInfo',
      component: () => import('../views/application/PerfectInfo.vue'),
      meta: { title: '完善信息', auth: true },
    },
    {
      path: '/applyshop',
      name: 'Applyshop',
      component: () => import('../views/application/Applyshop.vue'),
      meta: { title: '工商申请表', auth: true },
    },
    {
      path: '/accomplish',
      name: 'Accomplish',
      component: () => import('../views/application/Accomplish.vue'),
      meta: { title: '等待办理', auth: true },
    },
    {
      path: '/accomplishnom',
      name: 'AccomplishNom',
      component: () => import('../views/application/AccomplishNom.vue'),
      meta: { title: '成功注册', auth: true },
    },
    // 买家中心
    {
      path: '/buyercenter',
      component: () => import('../views/buyercenter/BuyerCenter.vue'),
      meta: { title: '，买家中心', auth: true },
      children: [
        {
          path: '/buyercenter/',
          name: 'BuyerCenter',
          component: () => import('../views/buyercenter'),
          meta: { title: '买家中心', auth: true }
        },
        {
          path: '/buyercenter/order-management',
          name: 'OrderManagement',
          component: () => import('../views/buyercenter/OrderManagement.vue'),
          meta: { title: '订单管理', auth: true }
        },
        {
          path: '/buyercenter/advance-management',
          name: 'AdvanceManagement',
          component: () => import('../views/buyercenter/AdvanceManagement.vue'),
          meta: { title: '预付贷款', auth: true }
        },
        {
          path: '/buyercenter/payment-management',
          name: 'PaymentManagement',
          component: () => import('../views/buyercenter/PaymentManagement.vue'),
          meta: { title: '支付管理', auth: true }
        },
        {
          path: '/buyercenter/balance-management',
          name: 'BalanceManagement',
          component: () => import('../views/buyercenter/Balance/index.vue'),
          meta: { title: '余额管理', auth: true },
          children:[
            {
              path:'/',
              name:'PNBANK',
              component:()=>import('../views/buyercenter/Balance/index.vue'),
              meta:{titel:'平安银行',auth:true}
            }
          ]
        },
        {
          path: '/buyercenter/batch-management',
          name: 'BatchManagement',
          component: () => import('../views/buyercenter/BatchManagement.vue'),
          meta: { title: '批量处理', auth: true }
        },
        {
          path: '/buyercenter/downstream',
          name: 'BatchManagement',
          component: () => import('../views/buyercenter/DownstreamManagement.vue'),
          meta: { title: '下游管理', auth: true }
        },
        {
          path: '/buyercenter/stock',
          name: 'BatchManagement',
          component: () => import('../views/buyercenter/stock.vue'),
          meta: { title: '库存管理', auth: true }
        },
      ]
    },
    {
      path: '/order',
      name: 'Order',
      component: () => import('../views/buyercenter/Order/Order.vue'),
      meta: { title: '订单管理', auth: true },
    },
    {
      path: '/stockorder',
      name: 'Order',
      component: () => import('../views/buyercenter/stockOrder.vue'),
      meta: { title: '库存创建订单', auth: true },
    },
    {
      path: '/account-center',
      component: () => import('../views/user/AccountCenter.vue'),
      meta: { title: '账户管理' },
      children: [
        {
          path: '/account-center/',
          name: 'AccountCenter',
          component: () => import('../views/user/MyCenter.vue'),
          meta: { auth: true },
        },
        {
          path: '/account-center/settings',
          name: 'AccountSettings',
          component: () => import('../views/user/AccountSettings.vue'),
          meta: { auth: true },
        },
        {
          path: '/account-center/natural',
          name: 'NaturalList',
          component: () => import('../views/user/NaturalList.vue'),
          meta: { auth: true },
        },
        {
          path: '/account-center/store',
          name: 'StoreLists',
          component: () => import('../views/user/StoreList.vue'),
          meta: { auth: true },
        },
        {
          path: '/account-center/apply',
          name: 'Apply',
          component: () => import('../views/user/Apply.vue'),
          meta: { auth: true },
        },
        {
          path: '/account-center/info',
          name: 'Info',
          component: () => import('../views/user/Info.vue'),
          meta: { auth: true },
        },
      ]
    },
    // 填写订单信息
    {
      path: '/fill-order',
      name: 'FillOrder',
      component: () => import('../views/fill/FillOrder.vue'),
      meta: { title: '填写订单信息' },
    },
    // 生成订单
    {
      path: '/succee-order',
      name: 'succeeOrder',
      component: () => import('../views/fill/succeeOrder.vue'),
      meta: { title: '生成订单' },
    },
    {
      path: '/about',
      name: 'About',
      component: () => import( '../views/static/About'),
      meta:{title:'关于我们'},
      children:[
        {
          path: '/about/',
          name: 'TaxPlatform',
          component: () => import('../views/static/About/TaxPlatform.vue'),
          meta: { title:'中再物贸云交易平台'},
        },
        {
          path: '/about/about-91',
          name: 'About-91',
          component: () => import('../views/static/About/About.vue'),
          meta: { title:'关于'},
        },
        {
          path: '/about/contact',
          name: 'ContactMe',
          component: () => import('../views/static/About/ContactMe.vue'),
          meta: { title:'联系我们'},
        },
      ]
    },
    // 帮助中心
    {
      path: '/helpcenter',
      name: 'Helpcenter',
      component: () => import('../views/helpcenter/Help'),
      meta: { title: '帮助中心' }
    },
    // 公告栏
    {
      path: '/notice',
      name: 'Notice',
      component: () => import('../views/notice'),
      meta: { title: '公告栏' }
    },
    // 公告栏详情
    {
      path: '/notice-details',
      name: 'NoticeDetails',
      component: () => import('../views/notice/default.vue'),
      redirect: '/notice-details/s1',
      meta: { title: '公告详情' },
      children: [
        {
          path: '/notice-details/s1',
          name: 'NoticeDetailsS1',
          component: () => import('../views/notice/s1.vue'),
          meta: { title: '中再物贸云交易平台上线' }
        },
        {
          path: '/notice-details/s2',
          name: 'NoticeDetailsS2',
          component: () => import('../views/notice/s2.vue'),
          meta: { title: '小规模增值税1%征收率将于2021年12月31结束' }
        },
        {
          path: '/notice-details/s3',
          name: 'NoticeDetailsS3',
          component: () => import('../views/notice/s3.vue'),
          meta: { title: '中再物贸云交易平台执行“实名认证”入驻' }
        },
        {
          path: '/notice-details/s4',
          name: 'NoticeDetailsS4',
          component: () => import('../views/notice/s4.vue'),
          meta: { title: '关于个体工商户代开发票限额的通知' }
        },
        {
          path: '/notice-details/s5',
          name: 'NoticeDetailsS5',
          component: () => import('../views/notice/s5.vue'),
          meta: { title: '中再物贸云网依法进行主体登记，履行纳税义务' }
        },
      ]
    },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/user/Register')
  },
  {
    path: '/forgotPwd',
    name: 'ForgotPwd',
    component: () => import(/* webpackChunkName: "about" */ '@/views/user/ForgotPwd.vue'),
    meta: { title: '忘记密码' },
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import('@/views/user/Result')
  },
  {
    path: '/switch-seller',
    name: 'SwitchSeller',
    component: () => import('../views/content/SwitchSeller.vue'),
    meta: { title: '切换卖家', auth: true },
  },
  {
    path: '/switch-buyer',
    name: 'SwitchBuyer',
    component: () => import('../views/buyercenter/SwitchBuyer.vue'),
    meta: { title: '切换买家', auth: true },
  },
  {
    path: '/store-info',
    name: 'StoreInfo',
    component: () => import('../views/content/StoreInfo.vue'),
  },
  {
    path: "/biocenose",
    name: 'Biocenose',
    component: Biocenose
  },
  {
    path: '/404',
    name: 'notFound',
    component: notFound
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
