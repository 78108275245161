import request from '@/utils/request'

// 查看店铺信息
export function queryStoreInfo(supplierId) {
    return request({
        url: '/trade/supplier/shop/info',
        method: 'get',
        params: {supplierId}
    })
}

// 商品品类层级数据获取
export function categoryTree(params) {
    return request({
        url: '/category/tree',
        method: 'get',
        params
    })
}
