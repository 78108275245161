<template>
  <div class="foot">
      <p>
        CopyRight © 中再物贸云 2005 - 2022 电信增值许可：<a href="https://tsm.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=豫B2-20200390&type=xuke" target="_blank">豫B2-20200390</a> icp备案： <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">豫ICP备20006957号</a>
      </p>
      <p class="fae">技术支持-浙江玖一再生供应链科技有限公司</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.foot {
    margin-top: 52px;
    color: #999999;
    font-size: 13px;
    min-width: 990px;
    @media screen and (max-width: 1440px){
    padding: 0 15px;
    }
    a {
        color: #999999;
        outline: none;
        cursor: pointer;
        transition: color .3s;
        text-decoration: none;
        &:hover {
            color: @primary;
        }
    }
    p {
        text-align: center;
    }
    .fae {
        margin-top: 6px;
    }
}
</style>