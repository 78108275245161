<template>
  <div>
    <!-- header-fix 悬浮时添加样式-->
    <header>
      <div class="header-wrapper" ref="header" :class="{'header-fix':isFixHeader}">
        <!-- 页面顶部 -->
        <Header />
        <!-- 搜索模块 -->
        <SearchTab></SearchTab>
      </div>
      <div class="header-fix-height" v-if="isFixHeader"></div>
    </header>
    
    <!-- 中间内容 -->
    <router-view :key="$route.fullPath" />

    <!-- 页面底部 -->
    <Footer />
  </div>
</template>
<script>
import Header from "./Header.vue";
import SearchTab from "./SearchTab.vue";
import Footer from "./Footer.vue";
export default {
  data(){
    return {
      isFixHeader:false,//是否顶部悬浮
    }
  },
  components: {
    Header,
    SearchTab,
    Footer,
  },
  mounted() {
    // console.log(this.$refs.header.offsetHeight)
    this.headerHeight = this.$refs.header.offsetHeight;
    window.addEventListener("scroll", this.menu);
  },
  methods: {
    menu() {
      let winScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if(winScroll>=this.headerHeight){
        this.isFixHeader = true
      }else{
        this.isFixHeader = false
      }
      // console.log(this.scroll);
    },
  },
};
</script>
<style lang="less" scoped>
 /deep/ .header-fix .web-title{
   display: none;
 }
/deep/.header-fix .type-tab{
   display: none;
 }
/deep/.header-fix .active-type{
   display: flex !important;
 }
/deep/ .header-fix .switch-status {
  margin-top: 6px !important;
}
/deep/ .header-fix .search-wrapper .search-ipt{
  width:296px!important;
  @media screen and (max-width: 1200px){
    width:205px!important;
  }
}
.header-wrapper{
  top: -100%;
}
.header-fix {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  transition: top 0.2s;
  box-shadow: 5px 0 5px #ccc;
}
.header-fix-height {
  height: 158px;
}
</style>