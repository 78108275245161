<template>
  <div>
    <div class="title"><a-icon :type="iconName" class="icon"/>{{label}}</div>
  </div>
</template>
<script>
export default {
  props:{
    label:{
      type:String,
      default:'标题',
      require:true
    },
    iconName:{
      type:String,
      default:'dribbble-square',
      require:true
    }
  }
}
</script>
<style lang="less" scoped>
.title{
  font-size: 20px;
  font-weight: bold;
}
.icon{
  color: @primary;
  margin-right: 8px;
}
</style>