<template>
  <div class="head-stream clear_fix">
    <div class="title">
      <MdTitle label="货源地直达" iconName="environment"></MdTitle>
    </div>
    <ul class="hs-list">
      <li>山东</li>
      <li>安徽</li>
      <li>浙江</li>
      <li>福建</li>
      <li>山西</li>
      <li>江西</li>
      <li>广西</li>
      <li>甘肃</li>
      <li>河南</li>
    </ul>
  </div>
</template>
<script>
import MdTitle from './MdTitle.vue'
export default {
  components:{
    MdTitle
  }
}
</script>
<style lang="less" scoped>
.head-stream{
  max-width: 1440px;
  min-width: 990px;
  margin: 0 auto;
  display: flex;
  background-color: #fff;
  padding: 0 35px;
  .title{
    font-size: 20px;
    float: left;
    font-weight: bold;
    line-height: 100px;
    width: 130px;
    .icon-ads{
      color: @primary;
      margin-right: 8px;
    }
  }
  .hs-list{
    display: flex;
    flex: 1;
    // justify-content: space-around;
    // line-height: 50px;
    margin-left: 60px;
    flex-wrap: wrap;
    padding: 20px 0;
    li{
      margin: 0 15px;
      font-size: 16px;
      padding: 20px 0 ;
      width: 90px;
      text-align: center;
    }
    
  }
}
</style>